import { LoadingScreen } from '@ppui/ui-components'
import { AppContainer } from 'components/AppContainer'
import { observer } from 'mobx-react'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useAuth } from './contexts'
import { Retry } from './Retry'
import { useStores } from './store/rootStore'

const NotFound = React.lazy(
  async () => await Retry(async () => await import('./routes/Investor/NotFound'))
)

export const RequireAuth = observer(
  ({
    children,
    featureFlag,
    fullWidth = false,
  }: {
    children: JSX.Element
    featureFlag?: string
    fullWidth?: boolean
  }): JSX.Element => {
    const { featureFlagsStore } = useStores()

    const { isAuthenticated, hasLoaded } = useAuth()

    const location = useLocation()

    if (!hasLoaded) {
      return <LoadingScreen />
    }

    if (!isAuthenticated) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return (
        <Navigate
          to={`/login?${location?.pathname.slice(1) ?? ''}${location?.search}` ?? ''}
          replace
        />
      )
    }

    const isPageEnabled =
      featureFlag !== undefined ? featureFlagsStore.isFeatureFlagEnabled(featureFlag) : true

    const isFlagLoading = featureFlagsStore.isFlagLoading()
    if (isFlagLoading) {
      return (
        <AppContainer isAuthenticated fullWidth={fullWidth}>
          <LoadingScreen />
        </AppContainer>
      )
    }

    if (!isPageEnabled) {
      return (
        <AppContainer isAuthenticated fullWidth={fullWidth}>
          <NotFound />
        </AppContainer>
      )
    }

    return (
      <AppContainer isAuthenticated fullWidth={fullWidth}>
        <div>{children}</div>
      </AppContainer>
    )
  }
)
