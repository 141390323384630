import { useQuery, UseQueryResult } from 'react-query'
import { ApiError } from 'src/routes/Investor/EditOrder/hooks'

import { getUserProfile, ProfileDTO } from '../../services/users'
import { ReactQueryKey } from '../types'

export function useGetUserProfile(): UseQueryResult<ProfileDTO, ApiError> {
  return useQuery<ProfileDTO, ApiError>([ReactQueryKey.USER_PROFILE], getUserProfile, {
    cacheTime: 0,
    notifyOnChangeProps: ['data', 'error'],
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })
}

export function useGetUserProfileForRequestAccessPage(): UseQueryResult<ProfileDTO, ApiError> {
  return useQuery<ProfileDTO, ApiError>(
    [ReactQueryKey.USER_PROFILE, 'Request access page'],
    getUserProfile,
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      notifyOnChangeProps: ['data', 'error'],
      refetchIntervalInBackground: false,
      retry: false,
    }
  )
}
