import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import { AlertComponent } from 'components/NewToast/Alert'
import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router'

import { AppRoutes } from './AppRoutes'
import { reportPageView } from './config/configureGoogleAnalytics'
import { FEATURES } from './constants/features'
import { HOTJAR_ENABLED_ENVS, HOTJAR_LIVE_ID, HOTJAR_PSE_ID } from './constants/hotjar'
import { useFeatureFlags } from './lib/hooks/useFeatureFlags'
import { hotJarFun } from './lib/hotjarHelpers'

export const App = (envName: any): JSX.Element => {
  const { isFeatureFlagEnabled } = useFeatureFlags()
  const isHotJarTrackingEnabled: boolean = isFeatureFlagEnabled(FEATURES.ENABLE_HOTJAR_TRACKING)

  const location = useLocation()
  const hotJarScriptRef = useRef(document.createElement('script'))

  useEffect(() => {
    const head = document.getElementsByTagName('head')[0]
    hotJarScriptRef.current.async = true

    const hotjarId = envName.envName === 'pse.primaryportal.com' ? HOTJAR_PSE_ID : HOTJAR_LIVE_ID

    if (
      isHotJarTrackingEnabled &&
      HOTJAR_ENABLED_ENVS.includes(envName.envName) &&
      !head.contains(hotJarScriptRef.current)
    ) {
      hotJarFun(
        window,
        document,
        'https://static.hotjar.com/c/hotjar-',
        '.js?sv=',
        head,
        hotjarId,
        hotJarScriptRef.current
      )
    } else if (head.contains(hotJarScriptRef.current)) {
      head.removeChild(hotJarScriptRef.current)
    }
  }, [isHotJarTrackingEnabled, envName])

  useEffect(() => {
    return reportPageView(location.pathname)
  }, [location.pathname])

  return (
    <>
      <AlertComponent />
      <AppRoutes />
    </>
  )
}
