import { LoadingScreen } from '@ppui/ui-components'
import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import { FEATURES } from './constants/features'
import { RequireAuth } from './RequireAuth'
import { Retry } from './Retry'

/* eslint-disable @typescript-eslint/promise-function-async */
const NewOrder = React.lazy(() => Retry(() => import('./routes/Investor/NewOrder')))
const EditOrder = React.lazy(() => Retry(() => import('./routes/Investor/EditOrder')))
const DealSetup = React.lazy(() => Retry(() => import('./routes/Banker/DealSetup')))
const NotFound = React.lazy(() => Retry(() => import('./routes/Investor/NotFound')))
const InvestorOrderIndications = React.lazy(() =>
  Retry(() => import('./routes/Investor/OrderIndications'))
)
const BankerOrSellSideSalesOrderIndications = React.lazy(() =>
  Retry(() => import('./routes/MultiUser/OrderIndications'))
)
const OrderView = React.lazy(() => Retry(() => import('./routes/Investor/OrderView')))
const OrderPreferences = React.lazy(() => Retry(() => import('./routes/Investor/OrderPreferences')))
const OrderHistory = React.lazy(() => Retry(() => import('./routes/Investor/OrderHistory')))
const AcknowledgementAcknowledged = React.lazy(() =>
  Retry(() => import('./routes/Investor/Acknowledgement/Acknowledged'))
)
const AcknowledgementAlreadyApplied = React.lazy(() =>
  Retry(() => import('./routes/Investor/Acknowledgement/AlreadyApplied'))
)
const AcknowledgementDeclined = React.lazy(() =>
  Retry(() => import('./routes/Investor/Acknowledgement/Declined'))
)
const AcknowledgementError = React.lazy(() =>
  Retry(() => import('./routes/Investor/Acknowledgement/Error'))
)
const AcknowledgementStale = React.lazy(() =>
  Retry(() => import('./routes/Investor/Acknowledgement/Stale'))
)
const MainPages = React.lazy(() => Retry(() => import('./routes/Investor/MainPages')))

const RequestAccess = React.lazy(() => Retry(() => import('./routes/Investor/RequestAccess')))
const DealsPage = React.lazy(() => Retry(() => import('./routes/DealsPage')))
const ApiDocs = React.lazy(() => Retry(() => import('./routes/Banker/ApiDocs')))

export const AppRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/">
        <Route
          index
          element={
            <Suspense fallback={<LoadingScreen />}>
              <MainPages />
            </Suspense>
          }
        />
        <Route
          path="new-order"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <NewOrder />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="deal-setup"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth featureFlag={FEATURES.ENABLE_FE_DEAL_SETUP_PAGE}>
                <DealSetup />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="order-indications"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth featureFlag={FEATURES.ENABLE_ORDER_INDICATION_PAGE_IOI}>
                <InvestorOrderIndications />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="view-order"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth featureFlag={FEATURES.ENABLE_ORDER_VIEW_PAGE_IOI}>
                <OrderView />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="edit-order-indication"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <EditOrder />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="preferences"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth featureFlag={FEATURES.ENABLE_PREFERENCES_PAGE_IOI}>
                <OrderPreferences />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="history"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth featureFlag={FEATURES.ENABLE_HISTORY_PAGE_IOI}>
                <OrderHistory />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="request-access"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequestAccess />
            </Suspense>
          }
        />
        <Route
          path="acknowledgement/acknowledged"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <AcknowledgementAcknowledged />
            </Suspense>
          }
        />
        <Route
          path="acknowledgement/already-applied"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <AcknowledgementAlreadyApplied />
            </Suspense>
          }
        />
        <Route
          path="acknowledgement/declined"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <AcknowledgementDeclined />
            </Suspense>
          }
        />
        <Route
          path="acknowledgement/stale"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <AcknowledgementStale />
            </Suspense>
          }
        />
        <Route
          path="acknowledgement/error"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <AcknowledgementError />
            </Suspense>
          }
        />
        <Route
          path="deals"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <DealsPage />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="deals/order-indications"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <BankerOrSellSideSalesOrderIndications />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="api-docs"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth fullWidth>
                <ApiDocs />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <RequireAuth>
                <NotFound />
              </RequireAuth>
            </Suspense>
          }
        />
      </Route>
    </Routes>
  )
}
